<template>
  <div>
    <vx-card title="Personalización de menú principal" title-color="black">
      <div class="flex lg:flex-no-wrap lg:ps-1 flex-wrap mt-3">
        <div class="sm:w-full lg:w-1/3 px-4">
          <img
            class="object-scale-down h-48 w-full"
            :src="developer.logo"
            v-show="!imageReadyToUplad"
          />
          <img
            class="object-scale-down h-48 w-full"
            :src="perfilImgTemp"
            v-show="imageReadyToUplad"
          />
          <div class="text-center">
            <p class="text-grey" v-if="!imageReadyToUplad">Imagen de Perfil</p>
          </div>
          <vs-popup
            title="Recortar imagen de perfil"
            :active.sync="isShowModal"
          >
            <input
              ref="input"
              type="file"
              name="image"
              accept="image/*"
              @change="setImage"
            />
            <VueCropper
              v-show="imgSrc"
              ref="cropper"
              :aspect-ratio="16 / 9"
              :src="imgSrc"
              preview=".preview"
            />
            <div v-show="imgSrc" class="actions">
              <vs-button class="mr-2" @click.prevent="zoom(0.2)">
                Acercar
              </vs-button>
              <vs-button class="ml-2" @click.prevent="zoom(-0.2)">
                Alejar
              </vs-button>
              <vs-button class="ml-2" @click.prevent="cropSuccess">
                Cortar imagen
              </vs-button>
            </div>
            <section v-show="perfilImgTemp" class="cropped-image py-4">
              <div class="cropped-image">
                <div
                  class="image-profile image-profile-size"
                  v-if="perfilImgTemp"
                  :style="{
                    backgroundImage: `url('${perfilImgTemp}')`
                  }"
                  alt="Cropped Image"
                />
                <div v-else class="crop-placeholder" />
              </div>
            </section>
            <div class="py-4">
              <vs-button type="border" @click="isShowModal = false"
                >Cancelar</vs-button
              >
              <vs-button
                v-if="perfilImgTemp"
                class="ml-2"
                color="success"
                @click="saveImage"
                >Guardar</vs-button
              >
            </div>
          </vs-popup>
          <vs-button
            @click="isShowModal = true"
            class="w-full mt-6"
            color="primary"
            icon-pack="feather"
            icon="icon-upload-cloud"
            >Remplazar imagen</vs-button
          >

          <input
            type="file"
            accept="image/png, image/jpeg"
            v-show="false"
            ref="btnPerfil"
            @change="mostrarImagenPerfil($event)"
          />
        </div>
        <div class="sm:w-full lg:w-3/4 px-4 p-4">
          <div>
            <div class="picker">
              <label class="picker__label">Color de fondo de menú</label>
              <VSwatches
                class="picker__selector mb-1"
                v-model="backgroundMenu"
                popover-x="right"
                swatches="text-advanced"
                show-fallback
              />
            </div>

            <div class="picker">
              <label class="picker__label">Color de fuente titulos menú</label>
              <VSwatches
                class="picker__selector mb-1"
                v-model="colorHeaderOption"
                popover-x="left"
                swatches="text-advanced"
                show-fallback
              />
            </div>
            <div class="picker">
              <label class="picker__label">Color de subtitulos menú</label>
              <VSwatches
                class="picker__selector mb-1"
                v-model="colorOptionMenu"
                popover-x="left"
                swatches="text-advanced"
                show-fallback
              />
            </div>
            <div class="picker">
              <label class="picker__label"
                >Color de fuente de opcion seleccionada</label
              >
              <VSwatches
                class="picker__selector mb-1"
                v-model="colorOptionSelected"
                popover-x="left"
                swatches="text-advanced"
                show-fallback
              />
            </div>
            <div class="picker">
              <label class="picker__label"
                >Color de fondo de opcion seleccionada</label
              >
              <VSwatches
                class="picker__selector mb-1"
                v-model="backgroundOptionSelected"
                popover-x="left"
                swatches="text-advanced"
                show-fallback
              />
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import S3 from "aws-s3";
import "babel-polyfill";
import VSwatches from "vue-swatches";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { developerApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import slugify from "../../../helpers/slugify";

export default {
  data() {
    return {
      imageReadyToUplad: false,
      isShowAppCrop: false,
      backgroundMenu: "",
      colorHeaderOption: "",
      colorOptionMenu: "",
      colorOptionSelected: "",
      backgroundOptionSelected: "",
      isShowModal: false,
      imgSrc: "",
      perfilImg: null,
      perfilImgTemp: "",
      perfilHeader: null,
      perfilHeaderTemp: "",
      settingValue: "",
      developer: null,
      developerTheme: null
    };
  },
  components: {
    VSwatches,
    VueCropper
  },
  async mounted() {
    this.settingValue = "MENU_SETTINGS";
    await this.getDeveloper();
  },
  methods: {
    async getDeveloper() {
      const developerId = this.$store.getters["auth/getUser"].developerId;
      this.developer = await developerApi.getDeveloperData(developerId);
      this.developerSettings();
    },
    getDeveloperThemeSettings() {
      const developerSettings = this.developer.property_developer_settings;
      if (!developerSettings.length) {
        return null;
      }
      const themeSetting = developerSettings.filter(
        setting =>
          setting.property_developer_setting_value_id === this.settingValue
      );
      if (!themeSetting.length) {
        return null;
      }
      return themeSetting[0];
    },
    developerSettings() {
      this.developerTheme = this.getDeveloperThemeSettings();
      if (!this.developerTheme) {
        this.defaultTheme();
        return;
      }

      this.backgroundMenu = this.developerTheme.special_value.backgroundMenu;
      this.colorHeaderOption = this.developerTheme.special_value.colorHeaderOption;
      this.colorOptionMenu = this.developerTheme.special_value.colorOptionMenu;
      this.colorOptionSelected = this.developerTheme.special_value.colorOptionSelected;
      this.backgroundOptionSelected = this.developerTheme.special_value.backgroundOptionSelected;

      const theme = {
        backgroundMenu: this.backgroundMenu,
        colorHeaderOption: this.colorHeaderOption,
        colorOptionMenu: this.colorOptionMenu,
        colorOptionSelected: this.colorOptionSelected,
        backgroundOptionSelected: this.backgroundOptionSelected
      };

      this.$store.dispatch("changeSettingMenu", theme);
    },
    defaultTheme() {
      this.backgroundMenu = this.$store.state.setting.backgroundMenu;
      this.colorHeaderOption = this.$store.state.setting.colorHeaderOption;
      this.colorOptionMenu = this.$store.state.setting.colorOptionMenu;
      this.colorOptionSelected = this.$store.state.setting.colorOptionSelected;
      this.backgroundOptionSelected = this.$store.state.setting.backgroundOptionSelected;
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    async updThemeSetting() {
      try {
        const developerId = this.$store.getters["auth/getUser"].developerId;
        const theme = {
          backgroundMenu: this.backgroundMenu,
          colorHeaderOption: this.colorHeaderOption,
          colorOptionMenu: this.colorOptionMenu,
          colorOptionSelected: this.colorOptionSelected,
          backgroundOptionSelected: this.backgroundOptionSelected
        };
        if (!this.developerTheme) {
          this.developerTheme = await developerApi.addDeveloperSetting(
            developerId,
            null,
            this.settingValue,
            theme
          );
        } else {
          const updateSetting = await developerApi.updDeveloperSettingById(
            this.developerTheme.id,
            null,
            theme
          );
          const [setting] = updateSetting.returning;
          this.developerTheme = setting;
        }
        this.$store.dispatch("changeSettingMenu", theme);
        this.$vs.notify({
          title: "Tema actualizado!",
          text: "Tema editado correctamente.",
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "developer");
        this.$vs.notify({
          title: "Ha ocurrido un error.",
          text: `No se pudo actualizar tema en este momento.`,
          color: "danger"
        });
      }
    },
    openAppCrop() {
      this.isShowAppCrop = !this.isShowAppCrop;
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    mostrarImagenPerfil() {
      this.perfilImg = null;
      this.perfilImg = event.target.files[0];
      this.imageReadyToUplad = true;
      const render = new FileReader();
      render.readAsDataURL(this.perfilImg);
      render.onload = e => {
        this.perfilImgTemp = e.target.result;
      };
    },

    cancelarPerfil() {
      this.perfilImgTemp = "";
      this.imageReadyToUplad = false;
    },

    cropSuccess() {
      this.perfilImgTemp = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.imageReadyToUplad = true;
    },

    async saveImage() {
      try {
        const developerId = this.$store.getters["auth/getUser"].developerId;
        const image = await this.dataURIToFile(this.perfilImgTemp);
        const fileName = Date.now();

        const file = await this.S3Client.uploadFile(image, fileName);
        const logoUrl = file.location;
        this.$store.dispatch("changeIconMenu", logoUrl);
        this.isShowModal = false;
        this.imgSrc = "";
        await developerApi.editDeveloperProfile(developerId, "logo", logoUrl);
        this.$vs.notify({
          title: "Edición correcta",
          text: `El campo logo fue editado correctamente.`,
          color: "success"
        });
        await this.getDeveloper();
      } catch (e) {
        sentryCaptureException(e, "developer");
        this.$vs.notify({
          title: "Ha ocurrido un error.",
          text: `No se pudo actualizar logo.`,
          color: "danger"
        });
      }
    },
    dataURIToFile: function(data) {
      let type = data.split(";", [1])[0];
      type = type.split(":", [2])[1];
      const date = new Date();
      return fetch(data)
        .then(response => response.arrayBuffer())
        .then(response => new File([response], date.getDate(), { type }));
    }
  },
  watch: {
    isShowModal() {
      this.imgSrc = "";
      if (this.isShowModal) {
        this.perfilImgTemp = "";
        this.imageReadyToUplad = false;
      }
    },
    async backgroundMenu() {
      if (this.$store.state.setting.backgroundMenu !== this.backgroundMenu) {
        await this.updThemeSetting();
      }
    },
    async colorHeaderOption() {
      if (
        this.$store.state.setting.colorHeaderOption !== this.colorHeaderOption
      ) {
        await this.updThemeSetting();
      }
    },
    async colorOptionMenu() {
      if (this.$store.state.setting.colorOptionMenu !== this.colorOptionMenu) {
        await this.updThemeSetting();
      }
    },
    async colorOptionSelected() {
      if (
        this.$store.state.setting.colorOptionSelected !==
        this.colorOptionSelected
      ) {
        await this.updThemeSetting();
      }
    },
    async backgroundOptionSelected() {
      if (
        this.$store.state.setting.backgroundOptionSelected !==
        this.backgroundOptionSelected
      ) {
        await this.updThemeSetting();
      }
    }
  },
  computed: {
    config() {
      const developerName = slugify(this.developer.name);
      return {
        bucketName: "flattlo-app",
        dirName: `${developerName}/profile`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL
      };
    },
    S3Client() {
      return new S3(this.config);
    }
  }
};
</script>

<style lang="scss">
.profile-dimension {
  width: 100%;
  height: 300px;
}
.image-profile {
  height: 200px;
  width: 200px;
}

.image-profile-size {
  background-size: contain;
  background-repeat: no-repeat;
}
.picker {
  display: flex;
  &__selector {
    .vue-swatches__trigger {
      border: solid 3px #d4dcdd;
      border-radius: 90em !important;
    }
  }

  &__label {
    flex: 60;
    font-size: 0.85rem;
  }
}
@media only screen and (max-width: 970px) {
  .picker {
    flex-direction: column;
  }
}
.pipedriveLogowhite {
  width: 150px;
}

.divMargin {
  display: block;
  margin-top: -1px !important;
}
.con-input-upload {
  background: #f5f5f5;
  width: 100% !important;
  height: 278px !important;
}
.con-img-upload .img-upload {
  width: 100% !important;
  height: 278px !important;
  margin: 5px;
}
.con-input-upload.disabled-upload {
  display: none !important;
}
</style>
