<template>
  <div>
    <vx-card title="Más configuraciones" title-color="black">
      <vs-button
        class="mt-6 vs-con-loading__container"
        id="PhoneUpdate"
        color="danger"
        type="filled"
        icon-pack="fas"
        icon="fa-save"
        @click="openDeletePopup"
        >Si, deseo eliminar perfil empresarial</vs-button
      >
    </vx-card>

    <vs-popup title="Confirmación" :active.sync="deletePopup">
      <p>
        Esta acción eliminará usuarios, propiedades, etc. ¿Deseas continuar?
      </p>
      <vs-button
        class="mt-6 vs-con-loading__container"
        id="PhoneUpdate"
        color="danger"
        type="filled"
        icon-pack="fas"
        icon="fa-save"
        @click="deleteAccount"
        >Si, deseo eliminar perfil empresarial</vs-button
      >
    </vs-popup>
  </div>
</template>

<script>
import { developerApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
export default {
  data() {
    return {
      deletePopup: false
    };
  },
  methods: {
    openDeletePopup() {
      this.deletePopup = true;
    },
    async deleteAccount() {
      try {
        this.$vs.loading();
        const developerId = this.$store.getters["auth/getUser"].developerId;

        await developerApi.deleteAccount(developerId);

        this.deletePopup = false;

        this.$vs.notify({
          time: 6000,
          title: `Cuenta eliminada correctamente.`,
          text: `Eliminada correctamente.`,
          color: "success"
        });

        this.$nextTick(() => {
          this.$store.dispatch("auth/logout");

          this.$vs.loading.close();

          this.$router.replace({
            path: "/login",
            replace: true
          });
        });
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible eliminar la cuenta`,
          color: "danger"
        });
        sentryCaptureException(e, "developer");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
