<template>
  <div v-if="expoId">
    <vx-card card-background="dark">
      <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
        <div slot="header">
          <vs-chip color="success" class="mr-4">2</vs-chip>
          <h2 style="color: white; font-weight: bold">Expoinmobiliaria</h2>
        </div>
        <div id="pipedrive-token">
          <div class="flex mb-4">
            <div class="w-full m-1 px-2">
              <label style="color: white">ID de expoinmobiliaria</label>
              <vs-input
                placeholder="Ingresa id de expoinmobiliaria"
                size="large"
                icon-pack="feather"
                class="fZise mt-1"
                icon="icon-terminal"
                color="primary"
                v-model="expoId"
                v-validate="'required'"
                name="Pipedrive_Key"
                :disabled="expoId ? true : false"
              />
            </div>
          </div>
        </div>

        <div id="pipedrive-token">
          <div class="flex mb-4">
            <div class="w-full m-1 px-2">
              <label style="color: white">Límite de proyectos</label>
              <vs-input
                size="large"
                icon-pack="feather"
                class="fZise mt-1"
                icon="icon-terminal"
                color="primary"
                v-model="expoProjectLimit"
                v-validate="'required'"
                name="Pipedrive_Key"
                disabled
              />
            </div>
            <div class="w-full m-1 px-2">
              <label style="color: white">Límite de proyectos destacados</label>
              <vs-input
                size="large"
                icon-pack="feather"
                class="fZise mt-1"
                icon="icon-terminal"
                color="primary"
                v-model="expoFeaturedLimit"
                v-validate="'required'"
                name="Pipedrive_Key"
                disabled
              />
            </div>
          </div>
        </div>
        <div id="pipedrive-url">
          <div class="flex mb-4">
            <div class="w-full m-1 px-2">
              <vs-input
                placeholder="Ingrese número de stand"
                size="large"
                icon-pack="feather"
                class="fZise"
                icon="icon-terminal"
                color="primary"
                v-model="expoStand"
                v-validate="'required'"
                name="pipedriveUrl"
              />
            </div>
            <div class="sm:w-1/9 p-2 flex">
              <vs-button
                v-if="expoStandAttrId === null"
                @click="
                  addDeveloperSetting(
                    expoStand,
                    'EXPO_STAND_NUMBER',
                    'Expo stand'
                  )
                "
                class="mt-0 vs-con-loading__container"
                color="success"
                type="filled"
                icon-pack="fas"
                icon="fa-save"
              ></vs-button>
              <vs-button
                v-if="expoStandAttrId !== null"
                @click="
                  updDeveloperSetting(expoStandAttrId, expoStand, 'Expo stand')
                "
                class="mt-0 vs-con-loading__container"
                color="success"
                type="filled"
                icon-pack="fas"
                icon="fa-save"
              ></vs-button>
            </div>
          </div>
        </div>
      </vs-collapse-item>
    </vx-card>
  </div>
</template>

<script>
import { developerApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
export default {
  data() {
    return {
      expoId: "",
      expoAttrId: null,
      expoStand: "",
      expoStandAttrId: null,
      expoFeaturedLimit: 0,
      expoProjectLimit: 0,
    };
  },
  async mounted() {
    await this.getDeveloper();
  },
  methods: {
    async addDeveloperSetting(value, setting, field) {
      try {
        const developerId = this.$store.getters["auth/getUser"].developerId;
        const developerSetting = await developerApi.addDeveloperSetting(
          developerId,
          value,
          setting
        );
        this.$vs.notify({
          title: `${field} ha sido actualizado correctamente.`,
          text: "Guardado correctamente.",
          color: "success",
        });
        const settingValues = this.getSettingValue(
          developerSetting.returning,
          setting
        );
        if (settingValues) {
          switch (settingValues.property_developer_setting_value_id) {
            case "EXPO_ID":
              this.setExpoId(settingValues);
              break;
            case "EXPO_STAND_NUMBER":
              this.setExpoStand(settingValues);
              break;
          }
        }
      } catch (e) {
        sentryCaptureException(e, "developer");
        this.$vs.notify({
          title: "Error al actualizar información.",
          text: "No hemos podido actualizar los datos en este momento.",
          color: "danger",
        });
      }
    },
    async getDeveloper() {
      const developerId = this.$store.getters["auth/getUser"].developerId;
      const developerData = await developerApi.getDeveloperData(developerId);

      if (developerData.property_developer_settings.length) {
        const expoId = this.getSettingValue(
          developerData.property_developer_settings,
          "EXPO_ID"
        );
        const expoStand =
          this.getSettingValue(
            developerData.property_developer_settings,
            "EXPO_STAND_NUMBER"
          ) || null;

        const expoProjectLimit =
          this.getSettingValue(
            developerData.property_developer_settings,
            "EXPO_PROJECT_LIMIT"
          ) || null;

        const expoFeaturedLimit =
          this.getSettingValue(
            developerData.property_developer_settings,
            "EXPO_PROJECT_FEATURED_LIMIT"
          ) || null;

        this.setExpoId(expoId);
        if (expoStand) this.setExpoStand(expoStand);
        if (expoProjectLimit) this.setExpoProjectLimit(expoProjectLimit);
        if (expoFeaturedLimit) this.setExpoFeaturedLimit(expoFeaturedLimit);
      }
    },
    getSettingValue(values, key) {
      const settings = values.filter(
        (value) => value.property_developer_setting_value_id === key
      );
      if (!settings.length) {
        return null;
      }
      return settings[0];
    },
    setExpoId(setting) {
      this.expoAttrId = setting.id;
      this.expoId = setting.value;
    },
    setExpoStand(setting) {
      this.expoStandAttrId = setting.id;
      this.expoStand = setting.value;
    },
    setExpoProjectLimit(setting) {
      this.expoProjectLimit = setting.value || 0;
    },
    setExpoFeaturedLimit(setting) {
      this.expoFeaturedLimit = setting.value || 0;
    },
    async updDeveloperSetting(settingId, value, field) {
      try {
        await developerApi.updDeveloperSettingById(settingId, value);
        this.$vs.notify({
          title: `${field} ha sido actualizado correctamente.`,
          text: "Guardado correctamente.",
          color: "success",
        });
      } catch (e) {
        sentryCaptureException(e, "developer");
        this.$vs.notify({
          title: "Error al actualizar información.",
          text: "No hemos podido actualizar los datos en este momento.",
          color: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
