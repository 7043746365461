<template>
  <div>
    <vx-card card-background="dark">
      <vs-collapse-item icon-pack="feather" icon-arrow="icon-arrow-down">
        <div slot="header">
          <vs-chip color="success" class="mr-4">1</vs-chip>
          <img
            class="pipedriveLogowhite"
            src="https://flattlo-app.s3.amazonaws.com/base-stock-images/logo-pipedrive-white.png"
            alt="Datos de pipedrive"
          />
        </div>
        <div id="pipedrive-token">
          <div class="flex mb-4">
            <div class="w-full m-1 px-2">
              <vs-input
                placeholder="Ingrese su token de Pipedrive"
                size="large"
                icon-pack="feather"
                class="fZise"
                icon="icon-terminal"
                color="primary"
                v-model="pipedriveToken"
                v-validate="'required'"
                name="Pipedrive_Key"
              />
            </div>
            <div class="sm:w-1/9 p-2 flex">
              <vs-button
                v-if="pipedriveTokenId === null"
                @click="
                  addDeveloperSetting(
                    pipedriveToken,
                    'PIPEDRIVE_TOKEN',
                    'Pipedrive token'
                  )
                "
                class="mt-0 vs-con-loading__container"
                color="success"
                type="filled"
                icon-pack="fas"
                icon="fa-save"
              ></vs-button>
              <vs-button
                v-if="pipedriveTokenId != null"
                @click="
                  updDeveloperSetting(
                    pipedriveTokenId,
                    pipedriveToken,
                    'Pipedrive token'
                  )
                "
                class="mt-0 vs-con-loading__container"
                color="success"
                type="filled"
                icon-pack="fas"
                icon="fa-save"
              ></vs-button>
            </div>
          </div>
        </div>
        <div id="pipedrive-url">
          <div class="flex mb-4">
            <div class="w-full m-1 px-2">
              <vs-input
                placeholder="Ingrese URL de Pipedrive"
                size="large"
                icon-pack="feather"
                class="fZise"
                icon="icon-terminal"
                color="primary"
                v-model="pipedriveUrl"
                v-validate="'required'"
                name="pipedriveUrl"
              />
            </div>
            <div class="sm:w-1/9 p-2 flex">
              <vs-button
                v-if="pipedriveUrlId === null"
                @click="
                  addDeveloperSetting(
                    pipedriveUrl,
                    'PIPEDRIVE_URL_DOMAIN',
                    'Pipedrive URL'
                  )
                "
                class="mt-0 vs-con-loading__container"
                color="success"
                type="filled"
                icon-pack="fas"
                icon="fa-save"
              ></vs-button>
              <vs-button
                v-if="pipedriveUrlId !== null"
                @click="
                  updDeveloperSetting(
                    pipedriveUrlId,
                    pipedriveUrl,
                    'Pipedrive URL'
                  )
                "
                class="mt-0 vs-con-loading__container"
                color="success"
                type="filled"
                icon-pack="fas"
                icon="fa-save"
              ></vs-button>
            </div>
          </div>
        </div>
      </vs-collapse-item>
    </vx-card>
  </div>
</template>

<script>
import { developerApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
export default {
  data() {
    return {
      pipedriveToken: "",
      pipedriveTokenId: null,
      pipedriveUrl: "",
      pipedriveUrlId: null,
    };
  },
  async mounted() {
    await this.getDeveloper();
  },
  methods: {
    async addDeveloperSetting(value, setting, field) {
      try {
        const developerId = this.$store.getters["auth/getUser"].developerId;
        const developerSetting = await developerApi.addDeveloperSetting(
          developerId,
          value,
          setting
        );
        this.$vs.notify({
          title: `${field} ha sido actualizado correctamente.`,
          text: "Guardado correctamente.",
          color: "success",
        });
        const settingValues = this.getSettingValue(
          developerSetting.returning,
          setting
        );
        if (settingValues) {
          switch (settingValues.property_developer_setting_value_id) {
            case "PIPEDRIVE_TOKEN":
              this.setPipedriveToken(settingValues);
              break;
            case "PIPEDRIVE_URL_DOMAIN":
              this.setPipedriveUrl(settingValues);
              break;
          }
        }
      } catch (e) {
        sentryCaptureException(e, "developer");
        this.$vs.notify({
          title: "Error al actualizar información.",
          text: "No hemos podido actualizar los datos en este momento.",
          color: "danger",
        });
      }
    },
    async getDeveloper() {
      const developerId = this.$store.getters["auth/getUser"].developerId;
      const developerData = await developerApi.getDeveloperData(developerId);
      if (developerData.property_developer_settings.length) {
        const pipedriveToken = this.getSettingValue(
          developerData.property_developer_settings,
          "PIPEDRIVE_TOKEN"
        );
        const pipedriveUrl = this.getSettingValue(
          developerData.property_developer_settings,
          "PIPEDRIVE_URL_DOMAIN"
        );
        this.setPipedriveToken(pipedriveToken);
        this.setPipedriveUrl(pipedriveUrl);
      }
    },
    getSettingValue(values, key) {
      const settings = values.filter(
        (value) => value.property_developer_setting_value_id === key
      );
      if (!settings.length) {
        return null;
      }
      return settings[0];
    },
    setPipedriveToken(pipedriveTokenSetting) {
      this.pipedriveTokenId = pipedriveTokenSetting.id;
      this.pipedriveToken = pipedriveTokenSetting.value;
    },
    setPipedriveUrl(pipedriveUrlSetting) {
      this.pipedriveUrlId = pipedriveUrlSetting.id;
      this.pipedriveUrl = pipedriveUrlSetting.value;
    },
    async updDeveloperSetting(settingId, value, field) {
      try {
        await developerApi.updDeveloperSettingById(settingId, value);
        this.$vs.notify({
          title: `${field} ha sido actualizado correctamente.`,
          text: "Guardado correctamente.",
          color: "success",
        });
      } catch (e) {
        sentryCaptureException(e, "developer");
        this.$vs.notify({
          title: "Error al actualizar información.",
          text: "No hemos podido actualizar los datos en este momento.",
          color: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
