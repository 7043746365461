var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vx-card',{attrs:{"card-background":"dark"}},[_c('vs-collapse-item',{attrs:{"icon-pack":"feather","icon-arrow":"icon-arrow-down"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('vs-chip',{staticClass:"mr-4",attrs:{"color":"success"}},[_vm._v("1")]),_c('img',{staticClass:"pipedriveLogowhite",attrs:{"src":"https://flattlo-app.s3.amazonaws.com/base-stock-images/logo-pipedrive-white.png","alt":"Datos de pipedrive"}})],1),_c('div',{attrs:{"id":"pipedrive-token"}},[_c('div',{staticClass:"flex mb-4"},[_c('div',{staticClass:"w-full m-1 px-2"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fZise",attrs:{"placeholder":"Ingrese su token de Pipedrive","size":"large","icon-pack":"feather","icon":"icon-terminal","color":"primary","name":"Pipedrive_Key"},model:{value:(_vm.pipedriveToken),callback:function ($$v) {_vm.pipedriveToken=$$v},expression:"pipedriveToken"}})],1),_c('div',{staticClass:"sm:w-1/9 p-2 flex"},[(_vm.pipedriveTokenId === null)?_c('vs-button',{staticClass:"mt-0 vs-con-loading__container",attrs:{"color":"success","type":"filled","icon-pack":"fas","icon":"fa-save"},on:{"click":function($event){return _vm.addDeveloperSetting(
                  _vm.pipedriveToken,
                  'PIPEDRIVE_TOKEN',
                  'Pipedrive token'
                )}}}):_vm._e(),(_vm.pipedriveTokenId != null)?_c('vs-button',{staticClass:"mt-0 vs-con-loading__container",attrs:{"color":"success","type":"filled","icon-pack":"fas","icon":"fa-save"},on:{"click":function($event){return _vm.updDeveloperSetting(
                  _vm.pipedriveTokenId,
                  _vm.pipedriveToken,
                  'Pipedrive token'
                )}}}):_vm._e()],1)])]),_c('div',{attrs:{"id":"pipedrive-url"}},[_c('div',{staticClass:"flex mb-4"},[_c('div',{staticClass:"w-full m-1 px-2"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fZise",attrs:{"placeholder":"Ingrese URL de Pipedrive","size":"large","icon-pack":"feather","icon":"icon-terminal","color":"primary","name":"pipedriveUrl"},model:{value:(_vm.pipedriveUrl),callback:function ($$v) {_vm.pipedriveUrl=$$v},expression:"pipedriveUrl"}})],1),_c('div',{staticClass:"sm:w-1/9 p-2 flex"},[(_vm.pipedriveUrlId === null)?_c('vs-button',{staticClass:"mt-0 vs-con-loading__container",attrs:{"color":"success","type":"filled","icon-pack":"fas","icon":"fa-save"},on:{"click":function($event){return _vm.addDeveloperSetting(
                  _vm.pipedriveUrl,
                  'PIPEDRIVE_URL_DOMAIN',
                  'Pipedrive URL'
                )}}}):_vm._e(),(_vm.pipedriveUrlId !== null)?_c('vs-button',{staticClass:"mt-0 vs-con-loading__container",attrs:{"color":"success","type":"filled","icon-pack":"fas","icon":"fa-save"},on:{"click":function($event){return _vm.updDeveloperSetting(
                  _vm.pipedriveUrlId,
                  _vm.pipedriveUrl,
                  'Pipedrive URL'
                )}}}):_vm._e()],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }