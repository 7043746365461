<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 mb-4 px-2">
        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-map"
              label="País de Origen:"
              color="primary"
              :placeholder="getDeveloper.country.name"
              name="Pais_Desarrolladora"
              disabled
            />
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-briefcase"
              label="Nombre de Empresa"
              color="primary"
              :placeholder="getDeveloper.name"
              v-validate="'required'"
              name="Nombre_Desarrolladora"
              disabled
            />
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-phone"
              label="Pbx o Teléfono"
              color="primary"
              v-model="phone"
              v-validate="'required'"
              name="Teléfono_Desarrolladora"
            />
          </div>

          <div
            class="sm:w-1/10 p-2 flex"
            v-if="phone !== this.getDeveloper.contact_phone"
          >
            <vs-button
              class="mt-6 vs-con-loading__container"
              id="PhoneUpdate"
              @click="editDeveloper('contact_phone', phone, 'Teléfono o Pbx.')"
              color="success"
              type="filled"
              icon-pack="fas"
              icon="fa-save"
            ></vs-button>
          </div>
        </div>

        <div class="flex">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise mb-4"
              icon="icon-globe"
              label="Página Web"
              color="primary"
              v-model="websiteUrl"
              v-validate="'required'"
              name="Página_Web"
            />
          </div>
          <div
            class="sm:w-1/9 p-2 flex"
            v-if="websiteUrl !== this.getDeveloper.website"
          >
            <vs-button
              class="mt-6 vs-con-loading__container"
              id="WebUpdate"
              @click="editDeveloper('website', websiteUrl, 'de Website.')"
              color="success"
              type="filled"
              icon-pack="fas"
              icon="fa-save"
            ></vs-button>
          </div>
        </div>
      </div>
      <div class="w-full sm:w-1/2 mb-4 px-2">
        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-facebook"
              label="Facebook, URL de Perfil"
              color="primary"
              v-model="facebookUser"
              v-validate="'required'"
              name="Perfil_Facebook"
            />
          </div>
          <div
            class="sm:w-1/9 p-2 flex"
            v-if="facebookUser !== this.getPropertiesValue('FACEBOOK_LINK')"
          >
            <vs-button
              class="mt-6 vs-con-loading__container"
              id="FacebookUpdate"
              @click="editDeveloperSocialNetwork('FACEBOOK_LINK', facebookUser)"
              color="success"
              type="filled"
              icon-pack="fas"
              icon="fa-save"
            ></vs-button>
          </div>
        </div>
        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-instagram"
              label="Instagram, URL de Perfil"
              color="primary"
              v-model="instagramUser"
              v-validate="'required'"
              name="Perfil_Instagram"
            />
          </div>
          <div
            class="sm:w-1/9 p-2 flex"
            v-if="instagramUser !== this.getPropertiesValue('INSTAGRAM_LINK')"
          >
            <vs-button
              class="mt-6 vs-con-loading__container"
              id="InstragramUpdate"
              @click="
                editDeveloperSocialNetwork('INSTAGRAM_LINK', instagramUser)
              "
              color="success"
              type="filled"
              icon-pack="fas"
              icon="fa-save"
            ></vs-button>
          </div>
        </div>
        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-linkedin"
              label="Linkedin, URL de Perfil"
              color="primary"
              v-model="linkedInUser"
              v-validate="'required'"
              name="Perfil_Linkedin"
            />
          </div>
          <div
            class="sm:w-1/9 p-2 flex"
            v-if="linkedInUser !== this.getPropertiesValue('LINKEDIN_LINK')"
          >
            <vs-button
              class="mt-6 vs-con-loading__container"
              id="LinkedinUpdate"
              color="success"
              type="filled"
              icon-pack="fas"
              icon="fa-save"
              @click="editDeveloperSocialNetwork('LINKEDIN_LINK', linkedInUser)"
            ></vs-button>
          </div>
        </div>

        <div class="flex mb-4">
          <div class="w-full m-1 px-2">
            <vs-input
              size="large"
              icon-pack="feather"
              class="fZise"
              icon="icon-message-circle"
              label="Whatsapp"
              color="primary"
              v-model="whatsappNumber"
              v-validate="'required'"
              name="Perfil_Whatsapp"
            />
          </div>
          <div
            class="sm:w-1/9 p-2 flex"
            v-if="whatsappNumber !== this.getPropertiesValue('WHATSAPP_NUMBER')"
          >
            <vs-button
              class="mt-6 vs-con-loading__container"
              id="WhatsappUpdate"
              color="success"
              type="filled"
              icon-pack="fas"
              icon="fa-save"
              @click="
                editDeveloperSocialNetwork('WHATSAPP_NUMBER', whatsappNumber)
              "
            ></vs-button>
          </div>
        </div>
      </div>

      <div class="flex w-full mb-4 px-2">
        <div class="w-full m-1">
          <vs-textarea
            counter="270"
            label="Descripción breve de la empresa:"
            :counter-danger.sync="counterDanger"
            v-model="description"
          />
        </div>
        <div
          class="sm:w-1/9 p-2 flex"
          v-if="description !== this.getDeveloper.description"
        >
          <vs-button
            class="mt-6 vs-con-loading__container"
            id="DescriptionUpdate"
            color="success"
            type="filled"
            icon-pack="fas"
            icon="fa-save"
            :disabled="counterDanger"
            @click="
              editDeveloper('description', description, 'de descripción.')
            "
          ></vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { developerApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      getDeveloper: null,
      counterDanger: "",
      facebookUser: null,
      instagramUser: null,
      linkedInUser: null,
      whatsappNumber: null,
      websiteUrl: null,
      phone: null,
      description: null
    };
  },

  methods: {
    async getDeveloperInfo() {
      try {
        const { developerId } = this.$store.getters["auth/getUser"];
        const developer = await developerApi.getDeveloperData(developerId);
        this.getDeveloper = developer;
        this.whatsappNumber = this.getPropertiesValue("WHATSAPP_NUMBER");
        this.linkedInUser = this.getPropertiesValue("LINKEDIN_LINK");
        this.instagramUser = this.getPropertiesValue("INSTAGRAM_LINK");
        this.facebookUser = this.getPropertiesValue("FACEBOOK_LINK");
        this.phone = this.getDeveloper.contact_phone;
        this.websiteUrl = this.getDeveloper.website;
        this.description = this.getDeveloper.description;

        this.$store.dispatch("developer/send_developer_data", developer);
      } catch (e) {
        sentryCaptureException(e, "developer");
      }
    },
    async editDeveloper(field, value, notification) {
      try {
        await developerApi.editDeveloperProfile(
          this.getDeveloper.id,
          field,
          value
        );

        this.$vs.notify({
          title: "Edición correcta",
          text: `El campo ${notification} fue editado correctamente.`,
          color: "success"
        });

        await this.getDeveloperInfo();
      } catch (e) {
        sentryCaptureException(e, "developer");
      }
    },
    async editDeveloperSocialNetwork(settingType, value) {
      try {
        const settingOption = this.getPropertiesValue(settingType);
        if (settingOption) {
          await developerApi.editDeveloperSettings(
            settingType,
            value,
            null,
            this.getDeveloper.id
          );
        } else {
          await developerApi.createDeveloperSetting(
            this.getDeveloper.id,
            value,
            settingType
          );
        }

        this.$vs.notify({
          title: "¡Red Social Editada!",
          text: "Campo editado correctamente.",
          color: "success"
        });

        await this.getDeveloperInfo();
      } catch (e) {
        sentryCaptureException(e, "developer");
      }
    },
    getPropertiesValue(setting) {
      const profile = this.getDeveloper.property_developer_settings.filter(
        n => n.property_developer_setting_value_id == setting
      );
      return profile.length ? profile[0].value : null;
    }
  },
  async mounted() {
    await this.getDeveloperInfo();
  }
};
</script>

<style lang="scss">
.fZise {
  width: 100%;
}
</style>
