<template>
    <div>
        <vx-card :title="`${title}`" title-color="black">
      <p class="mb-1">
       {{description}}
      </p>
    </vx-card>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                title:"Edición de perfil empresarial",
                description:"Actualice su informacion de perfil, algunos de estos datos serán publicos para SEO y cotizaciones."
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>