<template>
  <div>
    <TitleCard />
    <GeneralSetting class="mt-5"/>
    <ThemeSetting class="mt-5"/>
    <vx-card class="mt-5" title="Integraciones" title-color="black">
      <PipedriveSetting/>
      <ExpoSetting class="mt-5"/>
    </vx-card>
    <DeleteSetting class="mt-5" />
  </div>
</template>

<script>
import GeneralSetting from "../components/GeneralSetting.vue";
import TitleCard from "../../../components/GeneralComponents/TitleCard.vue";
import ThemeSetting from "../components/ThemeSetting.vue";
import PipedriveSetting from "../components/PipedriveSetting.vue";
import ExpoSetting from "../components/ExpoSetting.vue";
import BreadcrumbComponent from '../../../layouts/components/BreadcrumbComponent.vue';
import DeleteSetting from "../components/DeleteSetting.vue";

export default {
  components: {
    GeneralSetting,
    TitleCard,
    ThemeSetting,
    PipedriveSetting,
    DeleteSetting,
    ExpoSetting
  },
  async mounted() {
    this.$store.state.project.breadcrumbs = [{pageName: 'Perfil empresarial' , route:'/perfil-empresarial'}];
  }
};
</script>